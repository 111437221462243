import React from "react";
import FormUserComponent from "../FormUser/FormUserComponent";

const EditUserComponent = () => {
  return <div>
      <FormUserComponent />
    </div>;
};

export default EditUserComponent;

