import React from "react";
import FormRoleManagement from "../RoleForm/FormRoleComponent";

const EditRoleComponent = () => {
  return <div>
    <FormRoleManagement />
  </div>;
};

export default EditRoleComponent;

