import React from "react";
import FormSubjectComponent from "../SubjectForm/FormSubjectComponent";

const EditSubjectComponent = () => {
  return (
    <div>
      <FormSubjectComponent />
    </div>
  );
};

export default EditSubjectComponent;
