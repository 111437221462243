import React from "react";
import VehicleForm from "../VehicleForm/VehicleForm";

const AddVehicle = () => {
  return (
    <div>
      <VehicleForm />
    </div>
  );
};

export default AddVehicle;
