import React from "react";
import FeeMasterForm from "../FeeMasterForm/FeeMasterForm";

const AddFeeMaster = () => {
  return (
    <div>
      <FeeMasterForm />
    </div>
  );
};

export default AddFeeMaster;
