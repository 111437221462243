import React from "react";
import FormExamComponent from "../ExamForm/FormExamComponent";

const EditExamComponent = () => {
  return (
    <div>
      <FormExamComponent />
    </div>
  );
};

export default EditExamComponent;
