import React from "react";
import NoticeForm from "../NoticeForm/NoticeForm";

const AddNotice = () => {
  return (
    <div>
      <NoticeForm />
    </div>
  );
};

export default AddNotice;
