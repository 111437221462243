import React from "react";
import FormStudentManagement from "../StudentForm/FormStudentComponent";

const EditStudentComponent = () => {
  return (
    <div>
      <FormStudentManagement />
    </div>
  );
};

export default EditStudentComponent;
