import React from "react";
import FormClassManagement from "../ClassForm/FormClassComponent";

const EditClassComponent = () => {
  return (
    <div>
      <FormClassManagement />
    </div>
  );
};

export default EditClassComponent;
