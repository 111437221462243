import React from "react";
import FormAcademicComponent from "../AcademicYearForm/FormAcademicComponent";

const AddAcademicComponent = () => {
  return (
    <div>
      <FormAcademicComponent />
    </div>
  );
};

export default AddAcademicComponent;
