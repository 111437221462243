import React from "react";
import FormSubjectComponent from "../SubjectForm/FormSubjectComponent";

const AddSubjectComponent = () => {
  return (
    <div>
      <FormSubjectComponent />
    </div>
  );
};

export default AddSubjectComponent;
