import React from "react";
import FormOrganizationManagement from "../OrganizationForm/FormOrganizationComponent";

const AddOrganizationComponent = () => {
  return (
    <div>
      <FormOrganizationManagement />
    </div>
  );
};

export default AddOrganizationComponent;
