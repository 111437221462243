import React from "react";
import FormOrganizationManagement from "../OrganizationForm/FormOrganizationComponent";

const EditOrganizationComponent = () => {
  return (
    <div>
      <FormOrganizationManagement />
    </div>
  );
};

export default EditOrganizationComponent;
