import React from "react";
import MarksForm from "../MarksForm/MarksForm";

const EditMarks = () => {
  return (
    <div>
      <MarksForm />
    </div>
  );
};

export default EditMarks;
