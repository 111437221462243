import React from "react";
import FormSectionComponent from "../FormSection/FormSection";

const EditSectionComponent = () => {
  return (
    <div>
      <FormSectionComponent />
    </div>
  );
};

export default EditSectionComponent;
