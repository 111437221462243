import React from "react";
import FormExamTypeComponent from "../ExamTypeForm/FormExamTypeComponent";

const EditExamTypeComponent = () => {
  return (
    <div>
      <FormExamTypeComponent />
    </div>
  );
};

export default EditExamTypeComponent;
