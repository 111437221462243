import React from "react";
import FormAcademicComponent from "../AcademicYearForm/FormAcademicComponent";

const EditAcademicComponent = () => {
  return (
    <div>
      <FormAcademicComponent />
    </div>
  );
};

export default EditAcademicComponent;
