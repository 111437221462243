import React from "react";
import FormSchoolManagement from "../SchoolForm/FormSchoolComponent";

const AddSchoolComponent = () => {
  return (
    <div>
      <FormSchoolManagement />
    </div>
  );
};

export default AddSchoolComponent;
