import React from "react";
import FormExamTypeComponent from "../ExamTypeForm/FormExamTypeComponent";

const AddExamTypeComponent = () => {
  return (
    <div>
      <FormExamTypeComponent />
    </div>
  );
};

export default AddExamTypeComponent;
