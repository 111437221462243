import React from "react";
import FormFeeType from "../FormFeeType/FormFeeType";

const AddFeeType = () => {
  return <div>
      <FormFeeType />
    </div>;
};

export default AddFeeType;

