import React from "react";
import FeeDiscountForm from "../FeeDiscountForm/FeeDiscountForm";

const EditFeeDiscount = () => {
  return (
    <div>
      <FeeDiscountForm />
    </div>
  );
};

export default EditFeeDiscount;
