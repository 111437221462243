import React from "react";
import FormAssignmentComponent from "../AssignmentForm/FormAssignmentComponent";

const EditAssignmentComponent = () => {
  return (
    <div>
      <FormAssignmentComponent />
    </div>
  );
};

export default EditAssignmentComponent;
