import React from "react";
import FormExamComponent from "../ExamForm/FormExamComponent";

const AddExamComponent = () => {
  return (
    <div>
      <FormExamComponent />
    </div>
  );
};

export default AddExamComponent;
