import React from "react";
import FormRoleManagement from "../RoleForm/FormRoleComponent";

const AddRoleComponent = () => {
  return <div>
      <FormRoleManagement />
    </div>;
};

export default AddRoleComponent;

