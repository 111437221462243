import React from "react";
import FormClassManagement from "../ClassForm/FormClassComponent";

const AddClassComponent = () => {
  return (
    <div>
      <FormClassManagement />
    </div>
  );
};

export default AddClassComponent;
