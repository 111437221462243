import React from "react";
import FormSchoolManagement from "../SchoolForm/FormSchoolComponent";

const EditSchoolComponent = () => {
  return (
    <div>
      <FormSchoolManagement />
    </div>
  );
};

export default EditSchoolComponent;
