import React from "react";
import NoticeForm from "../NoticeForm/NoticeForm";

const EditNotice = () => {
  return (
    <div>
      <NoticeForm />
    </div>
  );
};

export default EditNotice;
