import React from "react";
import FormUserComponent from "../FormUser/FormUserComponent";

const AddUserComponent = () => {
  return <div>
      <FormUserComponent />
    </div>;
};

export default AddUserComponent;

