import React from "react";
import FormFeeType from "../FormFeeType/FormFeeType";

const EditFeeType = () => {
  return <div>
      <FormFeeType />
    </div>;
};

export default EditFeeType;

